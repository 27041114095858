import React from "react"
import { Link } from "gatsby"
import StandardLayout from "../../layouts/standard"
import coverImage from "../../images/about/our-leadership-cover.png"
import teamImage from "../../images/about/our-mission/team.jpg"
import jagdish from "../../images/team/jagdish.svg"
import manit from "../../images/team/manit.svg"
import satpal from "../../images/team/satpal.svg"
import sonia from "../../images/team/sonia.svg"
import renu from "../../images/team/renu.svg"
import sachinKumar from "../../images/team/sachin-kumar.svg"
import manav from "../../images/team/manav.svg"
import sachin from "../../images/team/sachin.svg"
import vinod from "../../images/team/vinod.svg"
import sanjeev from "../../images/team/sanjeev.svg"
import shrishti from "../../images/team/shrishti-1.svg"
import purva from "../../images/team/purva.svg"
import AboutUsPageComponent from "../../components/AboutUsPageComponent"

export default function OurLeadership(props) {
  return (
    <StandardLayout title="Our Leadership">
      <AboutUsPageComponent
        name="Our Leadership"
        description={`Leadership with vision and complete dedication, inspiring a skilled workforce`}
        coverImage={coverImage}
      />

      <section className="uk-section">
        <div className="uk-container uk-margin-large">
          <div className="uk-text-center">
            <h2
              className="uk-h2 uk-text-uppercase uk-width-xlarge "
              style={{ margin: "auto" }}
            >
              board of directors
            </h2>
            <div className="uk-width-large " style={{ margin: "auto" }}>
              We always strive to service our customers with the utmost
              integrity and to their complete satisfaction.
            </div>
          </div>
          <div
            className="uk-grid uk-margin-medium uk-grid-row-medium uk-grid-match uk-flex-center uk-child-width-1-4@s uk-child-1-2"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                <div className="uk-text-lead uk-text-uppercase">
                  Mr. Jagdish Rai Gupta
                </div>
                <div className="uk-text-meta">Managing Director, Chairman</div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                {/* <img
                  src={manit}
                  className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                  alt=""
                /> */}
                <div className="uk-text-lead  uk-text-uppercase">
                  Mr. Manit Gupta
                </div>
                <div className="uk-text-meta">Director</div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                {/* <img
                  src={satpal}
                  className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                  alt=""
                /> */}
                <div className="uk-text-lead  uk-text-uppercase">
                  Mr. Satpal Garg
                </div>
                <div className="uk-text-meta">Independent Director</div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                {/* <img
                  src={sonia}
                  className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                  alt=""
                /> */}
                <div className="uk-text-lead  uk-text-uppercase">
                  Ms. Sonia Goyal
                </div>
                <div className="uk-text-meta">Independent Director</div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                {/* <img
                  src={renu}
                  className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                  alt=""
                /> */}
                <div className="uk-text-lead  uk-text-uppercase">Renu Sood</div>
                <div className="uk-text-meta">Independent Director</div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                {/* <img
                  src={sachinKumar}
                  className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                  alt=""
                /> */}
                <div className="uk-text-lead  uk-text-uppercase">
                  Sachin Kumar Bhartiya
                </div>
                <div className="uk-text-meta">Nominee Director</div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                {/* <img
                  src={manav}
                  className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                  alt=""
                /> */}
                <div className="uk-text-lead  uk-text-uppercase">
                  Mr. Manav Gupta
                </div>
                <div className="uk-text-meta">Wholetime Director</div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                {/* <img
                  src={purva}
                  className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                  alt=""
                /> */}
                <div className="uk-text-lead  uk-text-uppercase">
                  Purva kansal
                </div>
                <div className="uk-text-meta">Independent Director</div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                {/* <img
                  src={sachin}
                  className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                  alt=""
                /> */}
                <div className="uk-text-lead  uk-text-uppercase">
                  Mr. Sachin Bhatla
                </div>
                <div className="uk-text-meta">Technical Director</div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-padding-small">
                {/* <img
                  src={vinod}
                  className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                  alt=""
                /> */}
                <div className="uk-text-lead  uk-text-uppercase">
                  Vinod Kumar
                </div>
                <div className="uk-text-meta">Key Managerial Personnel</div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-text-center uk-margin-medium-top">
          <h2
            className="uk-h2 uk-text-uppercase uk-width-xlarge "
            style={{ margin: "auto" }}
          >
            Key managerial personnel
          </h2>
        </div>
        <div
          className="uk-grid uk-margin-medium uk-grid-row-medium uk-grid-match uk-flex-center uk-child-width-1-4@s uk-child-1-2"
          data-uk-grid
        >
          <div>
            <div className="uk-card uk-card-default uk-padding-small">
              {/* <img
                src={sanjeev}
                className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                alt=""
              /> */}
              <div className="uk-text-lead  uk-text-uppercase">
                Mr. Sanjeev Vaid
              </div>
              <div className="uk-text-meta">CFO</div>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-default uk-padding-small">
              {/* <img
                src={shrishti}
                className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle"
                alt=""
              /> */}
              <div className="uk-text-lead  uk-text-uppercase">
                Ms. Srishty Chaudhary
              </div>
              <div className="uk-text-meta">Company Secretary</div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div
          class="uk-cover-container"
          style={{ minHeight: "60vh", height: "auto" }}
        >
          <img src={coverImage} alt="" data-uk-cover />
          <div class="uk-overlay-primary uk-position-cover"></div>
          <div class="uk-overlay uk-position-center">
            <div className="uk-container">
              <div>
                <div className="uk-card uk-card-default uk-padding-large">
                  <div className="uk-text-center">
                    <h2 className="uk-h2 uk-text-uppercase">Have Queries?</h2>
                    <div className="uk-width-large" style={{ margin: "auto" }}>
                      Connect with us for any query you need resolved and we’ll
                      help you realize your ideas.
                    </div>
                    <Link
                      className="color-primary uk-text-large"
                      to="/contact-us"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StandardLayout>
  )
}
